<template>
<button style="visibility:hidden" type="button" id="logoutbutton" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
  Logout Notice
</button>
<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel"><i class="icon fa fa-warning text-yellow"></i> Your session is about to expire.</h5>
      </div>
      <div class="modal-body">
       <p> You were automatically logged out of iService. This could be because you were inactive too long, or you were logged out from another browser window.</p>
        <a href="/">
            <button class="btn btn-primary" type="button">
                Login
            </button>
        </a>
    </div>
    
    </div>
  </div>
</div>

</template>
<script type="text/javascript">
export default {
  name: 'logouty notice',
   components: {
    
  },
  data() {
  },
  methods: {
    showModal() {
        document.getElementById("logoutbutton").click(); 
    },
  },
  mounted() { 
    this.showModal();
  }

}

</script>